import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import EventBus from "./event-bus";
export default {
  name: "Appsubmenu",
  props: {
    items: Array,
    menuMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
    sidebarController: Boolean,
    root: {
      type: Boolean,
      "default": false
    },
    parentMenuItemActive: {
      type: Boolean,
      "default": false
    },
    isSidebarCollapsed: {
      type: Boolean,
      "default": false
    }
  },
  emits: ["root-menuitem-click", "menuitem-click"],
  data: function data() {
    return {
      activeIndex: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    EventBus.on("reset-active-index", function () {
      if (_this.menuMode === "horizontal") {
        _this.activeIndex = null;
      }
    });
  },
  methods: {
    onMenuItemClick: function onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      //execute command
      if (item.command) {
        item.command({
          originalEvent: event,
          item: item
        });
        event.preventDefault();
      }
      if (item.items) {
        event.preventDefault();
      }
      if (this.root) {
        this.$emit("root-menuitem-click", {
          originalEvent: event
        });
      }
      if (item.items) {
        this.activeIndex = index === this.activeIndex ? null : index;
      } else {
        if (this.menuMode !== "static") {
          var ink = this.getInk(event.currentTarget);
          if (ink) {
            this.removeClass(ink, "p-ink-active");
          }
        }
      }
      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item
      });
    },
    onMenuItemMouseEnter: function onMenuItemMouseEnter(index) {
      if (this.root && this.menuActive && this.menuMode === "horizontal" && !this.isMobile()) {
        this.activeIndex = index;
      }
    },
    visible: function visible(item) {
      return typeof item.visible === "function" ? item.visible() : item.visible !== false;
    },
    isMobile: function isMobile() {
      return window.innerWidth <= 1091;
    },
    getInk: function getInk(el) {
      for (var i = 0; i < el.children.length; i++) {
        if (typeof el.children[i].className === "string" && el.children[i].className.indexOf("p-ink") !== -1) {
          return el.children[i];
        }
      }
      return null;
    },
    removeClass: function removeClass(element, className) {
      if (element.classList) element.classList.remove(className);else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    }
  }
};