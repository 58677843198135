// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/layout/images/modules/logo-t.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-menu-container[data-v-e630711a]{z-index:5}.hide-sidebar-btn[data-v-e630711a]{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___});outline:none;border:none;border-radius:50%;width:35px;height:35px;cursor:pointer;transition:all .2s ease-in-out;color:rgba(51,51,51,.8);justify-content:center}.hide-sidebar-btn[data-v-e630711a],.layout-topbar-left[data-v-e630711a]{display:flex;align-items:center;position:relative}.layout-topbar-left[data-v-e630711a]{box-shadow:0 1px 3px 0 rgba(0,0,0,.2);margin-top:0;height:50px;justify-content:space-between;z-index:50;background-color:#fff;padding:14px 16px 14px 16px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
