import _defineProperty from "/home/runner/work/mfe-sidebar/mfe-sidebar/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import AppSubmenu from "./AppSubmenu.vue";
import { useRouter } from "vue-router";
import { useGlobalStore } from "@/stores/global-store.ts";
import VTooltip from "v-tooltip";
import callIcon from "../assets/icons/call.vue";
import consent from "../assets/icons/consent.vue";
import cookieIcon from "../assets/icons/cookie.vue";
import diligence from "../assets/icons/diligence.vue";
import insurance from "../assets/icons/insurance.vue";
import justice from "../assets/icons/Justice.vue";
import losts from "../assets/icons/losts.vue";
import mapping from "../assets/icons/mapping.vue";
import orientation from "../assets/icons/orientation.vue";
import risk from "../assets/icons/risk.vue";
import services from "../assets/icons/services.vue";
import smart from "../assets/icons/smart.vue";
import solicitation from "../assets/icons/solicitation.vue";
import chart from "../assets/icons/chart.vue";
import clipboard from "../assets/icons/clipboardList.vue";
import settings from "../assets/icons/settings.vue";
import eyeScan from "../assets/icons/eyeScan.vue";
import keySquare from "../assets/icons/keySquare.vue";
import pulse from "../assets/icons/pulse.vue";
import delivery from "../assets/icons/delivery.vue";
import document from "../assets/icons/documentAdd.vue";
import history from "../assets/icons/history.vue";
import academic from "../assets/icons/academicCap.vue";
import box from "../assets/icons/box.vue";
import lock from "../assets/icons/lock.vue";
export default _defineProperty(_defineProperty(_defineProperty(_defineProperty({
  directives: {
    tooltip: VTooltip
  },
  components: {
    callIcon: callIcon,
    consent: consent,
    cookieIcon: cookieIcon,
    diligence: diligence,
    insurance: insurance,
    justice: justice,
    losts: losts,
    mapping: mapping,
    orientation: orientation,
    risk: risk,
    services: services,
    smart: smart,
    solicitation: solicitation,
    chart: chart,
    settings: settings,
    eyeScan: eyeScan,
    keySquare: keySquare,
    pulse: pulse,
    delivery: delivery,
    history: history,
    document: document,
    box: box,
    academic: academic,
    lock: lock
  },
  name: "AppMenu",
  props: {
    menuMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
    toggleSidebar: Function,
    sidebarController: Boolean,
    isMobileSidebarOpen: Boolean,
    logo: {
      type: String,
      "default": require("../../public/layout/images/modules/logo-trustechUX.png")
    }
  },
  data: function data() {
    return {
      model: [{
        //label: 'Favorites',
        label: "PRIVACIDADE",
        icon: keySquare,
        //items: [{ label: 'Dashboard', icon: 'home', to: '/' }],
        items: [{
          label: "Mapeamento de Dados",
          icon: mapping,
          items: [{
            label: "Atividades",
            icon: document,
            to: "/modules/mapeamento-de-dados#/home"
          }, {
            label: "Ativos",
            icon: box,
            to: "/home#/under-construction"
          }, {
            label: "Fornecedores",
            icon: delivery,
            to: "/home#/under-construction"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/modules/mapeamento-de-dados#/config"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/modules/mapeamento-de-dados#/instructions-for-use"
          }]
        }, {
          label: "Solicitações de Titulares",
          icon: solicitation,
          items: [{
            label: "Registros de solicitações",
            icon: clipboard,
            to: "/home#/under-construction"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/home#/under-construction"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/home#/under-construction"
          }]
        }, {
          label: "Cookies",
          icon: cookieIcon,
          items: [{
            label: "Registros",
            icon: clipboard,
            to: "/home#/under-construction"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/home#/under-construction"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/home#/under-construction"
          }]
        }, {
          label: "Consentimento",
          icon: consent,
          items: [{
            label: "Registros",
            icon: clipboard,
            to: "/home#/under-construction"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/home#/under-construction"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/home#/under-construction"
          }]
        }, {
          label: "Smart Workflow",
          icon: smart,
          items: [{
            label: "Guia LGPD",
            icon: lock,
            to: "/home#/under-construction"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/home#/under-construction"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/home#/under-construction"
          }]
        }, {
          label: "Orientações DPO",
          icon: orientation,
          to: "/home#/under-construction"
        }]
      }, {
        //label: 'UI Kit',
        label: "GRC",
        icon: eyeScan,
        items: [{
          label: "Gestão de Riscos",
          icon: risk,
          items: [{
            label: "Registros de riscos",
            icon: clipboard,
            to: "/modules/gestao-de-riscos#/home"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/modules/gestao-de-riscos#/config"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/modules/gestao-de-riscos#/instructions-for-use"
          }]
        }, {
          label: "Jurídico Preventivo",
          icon: justice,
          items: [{
            label: "Registro de chamados",
            icon: clipboard,
            to: "/modules/consultivo#/"
          }, {
            label: "Registro de chamados antigos",
            icon: history,
            to: "/modules/consultivo#/old-requests"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/modules/consultivo#/dashboard"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/modules/consultivo#/instructions-for-use"
          }]
        }, {
          label: "Due Dilligence de Terceiros",
          icon: diligence,
          items: [{
            label: "Due Dilligence",
            icon: clipboard,
            to: "/modules/due-diligence#/requests/home"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/modules/due-diligence#/settings"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/modules/due-diligence#/instructions-for-use"
          }]
        }, {
          label: "Prevenção de Perdas Patrimoniais",
          icon: losts,
          items: [{
            label: "Registro de solicitações",
            icon: clipboard,
            to: "/modules/prevencao-de-perdas#/requests/home"
          }, {
            label: "Dashboards",
            icon: chart,
            to: "/home#/under-construction"
          }, {
            label: "Configurações",
            icon: settings,
            to: "/modules/prevencao-de-perdas#/settings"
          }, {
            label: "Instruções de Uso",
            icon: academic,
            to: "/modules/prevencao-de-perdas#/instructions-for-use"
          }]
        }]
      }, {
        //label: 'Pages',
        label: "SMART ASSISTANCE ®",
        icon: pulse,
        items: [{
          label: "Serviços contratados",
          icon: services,
          to: "/home#/under-construction"
        }, {
          label: "Seguros",
          icon: insurance,
          to: "/home#/under-construction"
        }, {
          label: "Abrir chamado",
          icon: callIcon,
          to: "/home#/under-construction"
        }]
      }]
    };
  }
}, "components", {
  AppSubmenu: AppSubmenu
}), "emits", ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click"]), "setup", function setup() {
  var router = useRouter();
  var globalStore = useGlobalStore();
  return {
    router: router,
    globalStore: globalStore
  };
}), "methods", {
  navigateToHome: function navigateToHome() {
    window.location = "/home#/home";
  },
  onMenuItemClick: function onMenuItemClick(event) {
    this.$emit("menuitem-click", event);
  },
  onRootMenuItemClick: function onRootMenuItemClick(event) {
    this.$emit("root-menuitem-click", event);
  },
  /*onTopbarItemClick(event, item) {
    this.$emit("topbar-item-click", { originalEvent: event, item: item });
    event.preventDefault();
  },*/
  onTopbarSubItemClick: function onTopbarSubItemClick(event) {
    event.preventDefault();
  },
  // handleSignOut: async function () {
  //   event.preventDefault();
  //   if (!(await this.signOut())) return; // erro
  //   this.router.push({ name: "login" });
  // },
  navigateToSettings: function navigateToSettings() {
    this.$router.push({
      name: "general-settings"
    });
  },
  navigateToResetPassword: function navigateToResetPassword() {
    this.$router.push({
      name: "reset-password"
    });
  },
  handleHideLeftSideMenu: function handleHideLeftSideMenu() {
    this.globalStore.toggleLeftSideMenu("overlay");
  }
});