import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e630711a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "layout-menu-container"
};
var _hoisted_2 = {
  "class": "layout-topbar-left"
};
var _hoisted_3 = {
  href: "/home",
  style: {
    "margin": "0",
    "padding": "0"
  }
};
var _hoisted_4 = {
  id: "logolink",
  "class": "layout-topbar-logo",
  style: {
    "cursor": "pointer",
    "outline": "none"
  }
};
var _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_AppSubmenu = _resolveComponent("AppSubmenu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_AppSubmenu, {
    "class": "layout-menu",
    items: $data.model,
    "menu-mode": $props.menuMode,
    "parent-menu-item-active": true,
    "menu-active": $props.active,
    "mobile-menu-active": true,
    sidebarController: $props.sidebarController,
    root: true,
    onMenuitemClick: $options.onMenuItemClick,
    onRootMenuitemClick: $options.onRootMenuItemClick
  }, null, 8, ["items", "menu-mode", "menu-active", "sidebarController", "onMenuitemClick", "onRootMenuitemClick"])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("a", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
    id: "app-logo",
    alt: "trustech-logo",
    "class": "my-2",
    src: $props.logo,
    style: {
      "height": "42px",
      "width": "183px"
    }
  }, null, 8, _hoisted_5)])]), _createElementVNode("button", {
    "class": "hide-sidebar-btn",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.navigateToHome();
    })
  })])], 64);
}