import _typeof from "/home/runner/work/mfe-sidebar/mfe-sidebar/node_modules/@babel/runtime/helpers/esm/typeof.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-74be5080"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  role: "menu"
};
var _hoisted_2 = {
  key: 0,
  style: {
    "display": "flex",
    "flex-direction": "row",
    "align-content": "center",
    "gap": "28px",
    "margin": "0px",
    "background-color": "#f3f4f6",
    "color": "#13254d",
    "height": "44px",
    "font-weight": "bold",
    "font-size": "14px",
    "align-items": "center"
  },
  "class": "menu-first-item"
};
var _hoisted_3 = ["href", "target", "onMouseenter"];
var _hoisted_4 = {
  style: {
    "height": "21px"
  }
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = ["href", "target", "onClick", "onMouseenter"];
var _hoisted_7 = {
  style: {
    "height": "21px"
  }
};
var _hoisted_8 = {
  key: 0,
  "class": "pi pi-fw pi-angle-down layout-submenu-toggler"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_AppSubmenu = _resolveComponent("AppSubmenu", true);
  var _directive_tooltip = _resolveDirective("tooltip");
  var _directive_ripple = _resolveDirective("ripple");
  return $props.items ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, function (item, i) {
    return _openBlock(), _createElementBlock(_Fragment, null, [$options.visible(item) && !item.separator ? (_openBlock(), _createElementBlock("li", {
      key: item.label || i,
      "class": _normalizeClass([{
        'layout-root-menuitem': $props.root,
        'active-menuitem': $data.activeIndex === i && !item.disabled
      }]),
      role: "menuitem"
    }, [$props.root && $props.menuMode !== 'horizontal' && item.visible !== false ? (_openBlock(), _createElementBlock("section", _hoisted_2, [_withDirectives((_openBlock(), _createElementBlock("section", {
      style: _normalizeStyle({
        paddingLeft: $props.sidebarController ? '0px' : '18px'
      })
    }, [_typeof(item.icon) === 'object' ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
      key: 0,
      "class": "layout-menuitem-icon"
    }, null, 512)), [[_vShow, !$props.sidebarController], [_directive_tooltip, item.label]]) : _createCommentVNode("", true)], 4)), [[_directive_tooltip, item.label]]), $props.sidebarController ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      "class": _normalizeClass(['layout-menuitem-text', {
        hidden: _this.isSidebarCollapsed
      }]),
      style: {
        "display": "grid",
        "place-items": "center"
      }
    }, _toDisplayString(item.label), 3)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), item.to ? _withDirectives((_openBlock(), _createElementBlock("a", {
      key: 1,
      href: item.to,
      style: _normalizeStyle(item.style),
      "class": _normalizeClass([item["class"], 'p-ripple', {
        'p-disabled': item.disabled
      }]),
      "exact-active-class": "active-route",
      target: item.target,
      exact: "",
      onMouseenter: function onMouseenter($event) {
        return $options.onMenuItemMouseEnter(i);
      }
    }, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [_typeof(item.icon) === 'object' ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
      key: 0,
      "class": "layout-menuitem-icon"
    }, null, 512)), [[_directive_tooltip, item.label]]) : _createCommentVNode("", true)])), [[_directive_tooltip, item.label]]), _createElementVNode("span", {
      "class": _normalizeClass(['layout-menuitem-text menu-secondary-item', {
        hidden: $props.isSidebarCollapsed
      }])
    }, _toDisplayString(item.label), 3), item.items ? _withDirectives((_openBlock(), _createElementBlock("i", {
      key: 0,
      "class": "pi pi-fw pi-angle-down layout-submenu-toggler",
      translate: "no",
      onClick: function onClick($event) {
        return $options.onMenuItemClick($event, item, i);
      }
    }, null, 8, _hoisted_5)), [[_directive_tooltip, item.label]]) : _createCommentVNode("", true)], 46, _hoisted_3)), [[_directive_ripple]]) : _createCommentVNode("", true), !item.to ? _withDirectives((_openBlock(), _createElementBlock("a", {
      key: 2,
      href: item.url || '#',
      style: _normalizeStyle(item.style),
      "class": _normalizeClass([item["class"], 'p-ripple', {
        'p-disabled': item.disabled
      }]),
      target: item.target,
      onClick: function onClick($event) {
        return $options.onMenuItemClick($event, item, i);
      },
      onMouseenter: function onMouseenter($event) {
        return $options.onMenuItemMouseEnter(i);
      }
    }, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [_typeof(item.icon) === 'object' ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
      key: 0,
      "class": "layout-menuitem-icon"
    }, null, 512)), [[_directive_tooltip, item.label]]) : _createCommentVNode("", true)])), [[_directive_tooltip, item.label]]), _createElementVNode("span", {
      "class": _normalizeClass(['layout-menuitem-text menu-secondary-item', {
        hidden: $props.isSidebarCollapsed
      }])
    }, _toDisplayString(item.label), 3), item.items ? (_openBlock(), _createElementBlock("i", _hoisted_8)) : _createCommentVNode("", true)], 46, _hoisted_6)), [[_directive_ripple]]) : _createCommentVNode("", true), _createVNode(_Transition, {
      name: "layout-menu"
    }, {
      "default": _withCtx(function () {
        return [_withDirectives(_createVNode(_component_AppSubmenu, {
          items: $options.visible(item) && item.items,
          "menu-active": $props.menuActive,
          "menu-mode": $props.menuMode,
          "parent-menu-item-active": $data.activeIndex === i,
          onMenuitemClick: _cache[0] || (_cache[0] = function ($event) {
            return _ctx.$emit('menuitem-click', $event);
          })
        }, null, 8, ["items", "menu-active", "menu-mode", "parent-menu-item-active"]), [[_vShow, item.items && ($props.root && ($props.menuMode !== 'horizontal' || $props.menuMode === 'horizontal' && ($props.mobileMenuActive || $data.activeIndex !== null)) ? true : $data.activeIndex === i)]])];
      }),
      _: 2
    }, 1024)], 2)) : _createCommentVNode("", true)], 64);
  }), 256))])) : _createCommentVNode("", true);
}